import {customElement, property, queryAll, state} from "lit/decorators.js";
import {html, LitElement, type TemplateResult} from "lit";
import Styles from "./desktopHeaderMenu.lit.scss";
import {PageScrollbar} from "../../../../common/pageScrollbar";
import {resolve} from "../../../../container";
import type {EopNavigationFlyoutContent} from "./navigationFlyoutContent";
import {eventOccurredOutsideAll, KEYS, prepareEvents} from "../../../../common/utils/events";
import {GLOBAL} from "../../../../common/globals";
import {NavigationLevel1Item, NavigationRootItem, NavModel} from "../common/navModel";
import {Resolution} from "../../../../common/resolution";
import {NAVIGATION_TOGGLE_FLYOUT_EVENT, type NavigationToggleFlyoutEvent, type NavigationToggleFlyoutEventPayload} from "./desktopHeaderEvents";
import type {EopNavigationFlyoutToggle} from "./navigationFlyoutToggle";
import type {MetaLink} from "../common/navLinkTypes";
import {NAVIGATION_JUMP_INSIDE_PAGE_EVENT, type NavigationJumpInsidePageEvent} from "../common/headerEvents";
import {done, fresh, ManagingResources} from "../../../../common/lifetime";
import {elementFrom} from "../../../../common/utils/html";
import {PageFeatures} from "../../../elements/pageFeatures";
import {ClickTracking} from "../../../../tracking/clickTracking";
import {TrackingEventLocation, TrackingEventName, TrackingEventType} from "../../../../tracking/types";

@customElement("eop-desktop-header-menu")
export class EopDesktopHeader extends ManagingResources(LitElement) {

    public static readonly styles = Styles;

    @property({attribute: "with-language-switch", type: Boolean})
    public withLanguageSwitch: boolean = false;
    @property({attribute: "transparent", type: Boolean})
    public transparent: boolean;
    @property({attribute: "opaque", type: Boolean})
    public opaque: boolean;
    @property({attribute: "reduced", type: Boolean})
    public reduced: boolean;
    @property({reflect: true, type: Boolean})
    public open: boolean = false;
    @property({reflect: true, type: Boolean})
    public closedInstantly: boolean = false;

    @state()
    private navRoot: NavigationRootItem;
    @state()
    private metaLinks: MetaLink[] = [];
    @state()
    public currentNavId?: string;

    @queryAll("eop-navigation-flyout-toggle")
    private flyoutToggles: NodeListOf<EopNavigationFlyoutToggle>;
    @queryAll("eop-navigation-flyout-content")
    private flyoutContents: NodeListOf<EopNavigationFlyoutContent>;

    private backdrop: Element | undefined;
    private switchableScheme: boolean = false;

    public constructor(
        private pageScrollbar: PageScrollbar = resolve(PageScrollbar),
        private resolution: Resolution = resolve(Resolution),
        private tracking: ClickTracking = resolve(ClickTracking),
        private pageFeatures: PageFeatures = resolve(PageFeatures),
        private navModel: NavModel = resolve(NavModel)
    ) {
        super();
        this.metaLinks = this.navModel.getMetaLinks();
        this.navRoot = this.navModel.makeNavRoot();

        this.resolution.onWindowResize(() => {
            this.updateSelectedFlyoutContent();
        }, this);
    }

    public connectedCallback(): void {
        super.connectedCallback();

        this.switchableScheme = GLOBAL.bodyElement().classList.contains("switchable-scheme");
        this.backdrop = this.pageFeatures.insert(elementFrom(`<div class="header-desktop-menu-backdrop"></div>`), this);

        this.addEventListener(NAVIGATION_TOGGLE_FLYOUT_EVENT, (event: Event) => {
            const toggled = (event as NavigationToggleFlyoutEvent).detail;
            this.toggleOpen(toggled);
        });

        this.addEventListener(NAVIGATION_JUMP_INSIDE_PAGE_EVENT, (event: Event) => {
            const targetHref = (event as NavigationJumpInsidePageEvent).detail;
            this.navRoot.activateFor(this.navModel.getPathHierarchy(), targetHref);
            this.navRoot = NavigationRootItem.copyFrom(this.navRoot);
            this.closeFlyoutInstantly();
        });

        prepareEvents(GLOBAL.document())
            .boundTo(this)
            .on("keydown", (ev) => this.escapeKeyHandler(ev));
    }

    public render(): TemplateResult {
        return html`
            ${this.renderMetaAndSectorNavigation()}
            ${this.renderMainNavigation()}
        `;
    }

    private shouldRenderSearchLink(): boolean {
        return !!this.navModel.getActiveSector()?.searchHref || !!this.navModel.getSearchHref();
    }

    private renderMetaAndSectorNavigation(): TemplateResult | null {
        if (!this.shouldRenderSearchLink()
            && this.metaLinks.isEmpty()
            && this.navRoot.countSectors() <= 1
            && !this.withLanguageSwitch
            && !this.switchableScheme) {
            return null;
        }

        return html`
            <div class="meta-navigation" data-eventelement="metanavigation">
                ${(this.renderSectors())}
                ${(this.renderMetaLinks())}
                ${this.renderSearchLink()}
                ${this.renderColorSchemeToggle()}
                ${this.renderLanguageSwitch()}
            </div>
        `;
    }

    private renderSectors(): TemplateResult[] {
        return this.navRoot.getSectors()
            .map(sectorLink => this.renderSectorLink(sectorLink));
    }

    private renderSectorLink(sectorLink: NavigationLevel1Item): TemplateResult {
        const classes = `sector-link${sectorLink.active ? " highlighted" : ""}`;
        return html`
            <a class=${classes} href=${sectorLink.href} target=${sectorLink.target}
               title=${sectorLink.label}>${sectorLink.label}</a>`;
    }

    private renderMetaLinks(): TemplateResult[] {
        return this.metaLinks.map(metaLink => this.renderMetaLink(metaLink));
    }

    private renderMetaLink(metaLink: MetaLink): TemplateResult {
        return html`
            <a href=${metaLink.href} target=${metaLink.target} title=${metaLink.label}
               class="meta-link">${metaLink.label}</a>`;
    }

    private renderSearchLink(): TemplateResult {
        return html`
            <eop-navigation-search-link></eop-navigation-search-link>`;
    }

    private renderColorSchemeToggle(): TemplateResult | null {
        if (!this.switchableScheme) {
            return null;
        }

        return html`
            <eop-color-scheme-toggle inside-sheet="false"></eop-color-scheme-toggle>`;
    }

    private renderLanguageSwitch(): TemplateResult | null {
        if (!this.withLanguageSwitch) {
            return null;
        }

        return html`
            <eop-desktop-language-switch></eop-desktop-language-switch>`;
    }

    private renderMainNavigation(): TemplateResult | null {
        const activeSector = this.navRoot.getActiveSector();
        if (!activeSector?.hasSubItems()) {
            return null;
        }

        return html`
            <nav class="main-navigation" data-eventelement="mainnavigation">
                ${(this.renderFlyoutToggle(activeSector))}
            </nav>
            <div class="desktop-flyout-viewport">
                <div class="desktop-flyout-area">
                    <div class="desktop-flyout-container">
                        <div class="desktop-flyout-panel" data-eventelement="mainnavigation-flyout">
                            ${this.renderFlyoutContents(activeSector)}
                        </div>
                    </div>
                </div>
            </div>
        `;
    }

    private renderFlyoutToggle(activeSector: NavigationLevel1Item): TemplateResult[] | null {
        return activeSector.getSubItems().map(item => html`
            <eop-navigation-flyout-toggle .model=${item}></eop-navigation-flyout-toggle>
        `);
    }

    private renderFlyoutContents(activeSector: NavigationLevel1Item): TemplateResult [] {
        return activeSector.getSubItems()
            .filter(item => item.hasSubItems())
            .map(item => html`
                <eop-navigation-flyout-content
                        .model=${item}
                        .closedInstantly=${this.closedInstantly}>
                </eop-navigation-flyout-content>
            `);
    }

    private toggleOpen(navItem: NavigationToggleFlyoutEventPayload): void {
        if (this.currentNavId === navItem.id) {
            this.closeFlyout();
        } else if (this.open) {
            this.selectFlyout(navItem);
        } else {
            this.openFlyout(navItem);
        }
    }

    private openFlyout(navItem: NavigationToggleFlyoutEventPayload): void {
        const lifetime = fresh(this, "flyout");
        this.open = true;
        this.closedInstantly = false;
        this.backdrop?.classList.add("visible");

        this.pageScrollbar.disablePageScrollability();
        this.selectFlyout(navItem);
        prepareEvents(GLOBAL.bodyElement())
            .boundTo(lifetime)
            .on("click", e => this.closeOnClickOutside(e));
    }

    private closeOnClickOutside(event: Event): void {
        const flyoutPanel = this.renderRoot.querySelector(".desktop-flyout-panel")!;
        if (eventOccurredOutsideAll(event, flyoutPanel, ...this.flyoutToggles)) {
            this.closeFlyout();
            this.trackClosingFlyout();
        }
    }

    private trackClosingFlyout(): void {
        this.tracking.handleNavigationInteract({
            name: TrackingEventName.NAVIGATION_INTERACT,
            data: {
                label: "desktop-flyout-toggle",
                contentId: "header.desktop-flyout-toggle",
                location: TrackingEventLocation.HEADER,
                type: TrackingEventType.CLOSE
            }
        });
    }

    private closeFlyout(): void {
        this.open = false;
        this.backdrop?.classList.remove("visible");

        this.pageScrollbar.enablePageScrollability();
        this.deselectAllFlyouts();
        this.style.setProperty("--flyout-height", `0px`);
        done(this, "flyout");
    }

    private closeFlyoutInstantly(): void {
        this.closeFlyout();
        this.closedInstantly = true;
    }

    private selectFlyout(navItem: NavigationToggleFlyoutEventPayload): void {
        this.deselectAllFlyouts();
        this.currentNavId = navItem.id;
        this.flyoutToggles.forEach(flyoutToggle => {
            if (flyoutToggle.hasId(navItem.id)) {
                flyoutToggle.selected = true;
            }
        });
        this.flyoutContents.forEach(flyoutContent => {
            if (flyoutContent.hasId(navItem.id)) {
                flyoutContent.selected = true;
                this.updateFlyoutContent(flyoutContent);
                flyoutContent.onTabOut(() => this.closeFlyout());
            }
        });
    }

    private escapeKeyHandler(evt: KeyboardEvent): void {
        if (evt.key === KEYS.ESCAPE) {
            evt.stopPropagation();
            this.closeFlyout();
        }
    };

    private deselectAllFlyouts(): void {
        this.currentNavId = undefined;
        this.flyoutToggles.forEach(flyoutToggle => flyoutToggle.selected = false);
        this.flyoutContents.forEach(flyoutContent => flyoutContent.selected = false);
    }

    private updateSelectedFlyoutContent(): void {
        this.flyoutContents.forEach(flyoutContent => {
            if (flyoutContent.selected) {
                this.updateFlyoutContent(flyoutContent);
            }
        });
    }

    private updateFlyoutContent(flyoutContent: EopNavigationFlyoutContent): void {
        this.style.setProperty("--flyout-height", `${(flyoutContent.clientHeight)}px`);
        this.style.setProperty("--arrow-up-offset", `${this.calculateArrowUpOffsetX()}px`);
    }

    private calculateArrowUpOffsetX(): number {
        const panel = this.renderRoot.querySelector(".desktop-flyout-panel")!;
        const panelRect = panel.getBoundingClientRect();

        const selectedFlyoutToggle = Array.from(this.flyoutToggles)
            .findFirst(toggle => toggle.selected)!;
        const toggleRect = selectedFlyoutToggle.getBoundingClientRect();

        const flyoutToggleCenterX = toggleRect.x + (toggleRect.width / 2);
        return flyoutToggleCenterX - panelRect.x - 2;
    }
}